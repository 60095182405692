<template>
  <div class="form">
    <div class="content" v-if="!isMobile || !$store.state.popup.id && (!isMobile || !$store.state.is_popup_shown)">
      <Backlink title="notifications" :service="currentServiceStr" />
      <div class="content__container content__container_profile notify-container">
        <div v-if="services.length > 1">
          <v-select
            :items="services"
            class="service__select"
            :label="'documents_service' | localize"
            item-text="name"
            item-value="service_id"
            outlined
            v-model="currentService"
          ></v-select>
        </div>
        <div class="container_notifications" v-if="currentService">
          <ItemsListNotifications @items="showButtons" :service="currentService" />
        </div>
        <div v-if="showCancel">
          <p class="info info_notify">{{ 'notify_cancel_text' | localize }}</p>
        </div>
        <div v-if="showPayment">
          <p class="info info_notify">{{ 'notify_text' | localize }}</p>
          <button type="button" class="button button__form" @click="$store.commit('togglePopupText', { id: 1 })">
            {{ "pay" | localize }}
          </button>
        </div>
        <div v-else-if="showBanks">
          <router-link :to="{ name: 'Bank offers', params: { service_id: currentService} }" class="button button__form" tag="button">
          {{ "bank_offers" | localize }}
          </router-link>
        </div>
      </div>
    </div>

    <div class="popup popup_active popup_info" v-if="$store.state.popup.id">
      <div class="popup__overlay popup__overlay_active">
        <div class="popup__center" @click.self="$store.commit('togglePopupText')">
          <div class="popup__window popup__window_blue">
            <button class="close popup__close" @click.self="$store.commit('togglePopupText')"></button>
            <div class="popup__logo"><router-link to="/" class="logo">BANKIM<span>ONLINE</span></router-link></div>
            <div class="popup__scrollcontainer ">
              <div class="popup__scrollcontainer_inner">
                <AddCard returnPayment v-if="$store.state.popup.id == 'card'" />
                <PaymentService v-else :service="currentService" :card_id="$store.state.popup.id > 1 ? $store.state.popup.id : null" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemsListNotifications from '@/components/profile/ItemsListNotifications'
import PaymentService from '@/components/PaymentService.vue'
import AddCard from '@/components/profile/AddCard'
import Backlink from '@/components/base/Backlink'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Notifications',
  components: { ItemsListNotifications, PaymentService, AddCard, Backlink },
  mixins: [commonMixin],
  data: () => ({
    services: [],
    currentService: null,
    currentServiceStr: null,
    dialog: false,
    showPayment: false,
    showBanks: false,
    showCancel: false,
    error: null
  }),
  created () {
    this.loadServices()
  },
  watch: {
    currentService () {
      this.changedService()
    }
  },
  methods: {
    changedService() {
      this.currentServiceStr = this.getServiceStr(this.currentService)
    },
    showButtons (cnt) {
      const service = this.services.find(s => s.service_id === this.currentService)
      this.showPayment = service.status === 'payment' && cnt > 0
      this.showBanks = service.status === 'active' && cnt > 0
      this.showCancel = service.status === 'canceled'
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1025px) {
  .notify-container {
    padding: 50px 100px 0 0;
  }
  #app.locale_heb {
    .notify-container {
      padding: 50px 0 0 100px;
    }
  }
}
</style>
