<template>
  <div class="b-newCard">
    <div class="b-newCard_title">{{ "addCard" | localize }}</div>
    <div class="b-newCard_text">{{ "add_card_text" | localize }}</div>
    <div class="b-newCard_card b-newCard_card__position b-newCard_card__size">
      <card :card-data="cardData" :key="componentKey"/>
    </div>
    <div class="b-newCard_inputs">
      <v-text-field
        v-if="step == 2"
        class="b-newCard_input input"
        :label="'cardNumber' | localize"
        placeholder="0000 0000 0000 0000"
        v-mask="cardMask"
        outlined
        v-model="cardData.number"
        @input="submitHandler($v.cardData.number)"
        :hint="($v.cardData.number.$dirty && !$v.cardData.number.required && (!$v.cardData.number.maxLength || !$v.cardData.number.minLength) ? 'form_reqiered' : 'empty') | localize"
        :class="{ invalid: ($v.cardData.number.$dirty && !$v.cardData.number.required) || !$v.cardData.number.maxLength || !$v.cardData.number.minLength }"
      ></v-text-field>

      <v-menu
        v-if="step === 3"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="b-newCard_input v-input-calendar"
            v-model="computedDateFormatted"
            placeholder="MM/YY"
            label="MM/YY"
            outlined
            persistent-hint
            append-icon="icon-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            @change="submitHandler($v.cardData.date)"
            :class="{ invalid: $v.date.$dirty && !$v.date.required }"
            :hint="($v.date.$dirty && !$v.date ? 'form_reqiered' : 'empty') | localize"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          type="month"
          no-title
          full-width
          :min="dateFrom"
          @input="submitHandler($v.date)"
          :class="{ invalid: $v.date.$dirty && !$v.date.required }"
          :hint="($v.date.$dirty && !$v.date ? 'form_reqiered' : 'empty') | localize"
        ></v-date-picker>
      </v-menu>

      <v-text-field
        v-if="step === 4"
        class="b-newCard_input"
        label="CVV"
        :placeholder="'cardCvv' | localize"
        outlined
        type="number"
        v-model="cardData.cvv"
        v-mask="cvvMask"
        @input="submitHandler($v.cardData.cvv)"
        :hint="($v.cardData.cvv.$dirty && !$v.cardData.cvv.required && (!$v.cardData.cvv.maxLength || !$v.cardData.cvv.minLength) ? 'form_reqiered' : 'empty') | localize"
        :class="{ invalid: ($v.cardData.cvv.$dirty && !$v.cardData.cvv.required) || !$v.cardData.cvv.maxLength || !$v.cardData.cvv.minLength }"
      ></v-text-field>

      <v-text-field
        v-if="step === 5"
        class="b-newCard_input"
        :label="'cardHolderName' | localize"
        :placeholder="'cardHolderName' | localize"
        outlined
        type="text"
        v-model="cardData.cardholder"
        @input="submitHandler($v.cardData.cardholder)"
        :hint="hintArray(
          $v.cardData.cardholder, [
            {rule: $v.cardData.cardholder.required, message: 'form_reqiered'},
            {rule: $v.cardData.cardholder.alpha, message: 'form_wrong_abc_eng'}
          ]
        ) | localize"
        :class="{ invalid: ($v.cardData.cardholder.$dirty && !$v.cardData.cardholder.required) || ($v.cardData.cardholder.$dirty && !$v.cardData.cardholder.alpha)}"
      ></v-text-field>

    </div>
    <div class="b-newCard_button">
      <app-button :onClick="nextStep" :disabled="disable" :labelBtn="step < 5 ? 'continue' : 'save'" class="button-width"/>
    </div>
  </div>
</template>

<script>
import Card from '@/components/profile/Card'
import AppButton from '@/components/profile/AppButton'
import { formMixin } from '@/mixins/formMixin.js'

const { required, minLength, maxLength } = require('vuelidate/lib/validators')
export default {
  name: 'add-new-card',
  props: { returnPayment: { type: Boolean, default: false } },
  components: { AppButton, Card },
  mixins: [formMixin],
  data () {
    return {
      dateFrom: new Date().toISOString().substr(0, 10),
      menu: false,
      step: 2,
      date: '',
      cardData: {
        class: 'card__default',
        number: null,
        date: this.computedDateFormatted,
        cvv: '',
        cardholder: ''
      },
      disable: true,
      componentKey: 0,
      cardMask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
      cvvMask: [/\d/, /\d/, /\d/]
    }
  },
  methods: {
    nextStep () {
      this.disable = true
      this.step++

      if (this.step === 6) {
        this.$store.commit('loading', true)
        this.cardData.user_id = this.$store.state.user.id || 0
        this.cardData.date = this.date
        this.axios
          .post('user_cards', this.cardData)
          .then((response) => {
            this.$store.commit('loading', false)
            this.$store.commit('togglePopupText')
            if (this.returnPayment) {
              this.$store.commit('togglePopupText', { id: response.data.id })
            } else {
              this.$router.go(this.$router.currentRoute)
            }
          })
          .catch((error) => {
            this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
            this.$store.commit('loading', false)
          })
      }
    },
    submitHandler (field) {
      this.checkForm(field)
      this.componentKey++

      if (this.step === 2 && field.required && field.minLength) {
        this.disable = false
      } else if (this.step === 3 && field.required) {
        this.menu = false
        this.cardData.date = this.formatDateForCard(this.date)
        this.cardData.dt = this.formatDateForCard(this.date)
        this.disable = false
      } else if (this.step === 4 && field.required && field.minLength) {
        this.disable = false
      } else if (this.step === 5 && field.required && field.alpha) {
        this.disable = false
      } else {
        this.disable = true
      }
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDateForCard(this.date)
    }
  },
  validations: {
    cardData: {
      number: { required, minLength: minLength(19), maxLength: maxLength(19) },
      date: { required },
      cvv: { required, minLength: minLength(3), maxLength: maxLength(3) },
      cardholder: { required, alpha: val => /^[a-z]/i.test(val) }
    },
    date: { required }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/default/vars.scss";
.b-newCard {
  width: 100%;
  margin: -50px auto 0;
  max-width: 330px;

  .b-newCard_text,
  .b-newCard_title {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  .b-newCard_text {
    font-size: 16px;
  }

  .button-width {
    width: 100%;
  }

  &_card {
    display: flex;
    justify-content: center;
  }
  &_inputs {
    margin-top: -18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .b-newCard_input {
      width: 100% !important;
    }
  }
}

#app.locale_heb {
  .b-newCard_inputs {
    .b-newCard_input {
      direction: rtl;
    }
  }
}

// Media style
@media screen and (max-width: 1024px) {
  .b-newCard {
    margin: 0 auto;

    &_card {
      display: block;
    }

    &_inputs {
      margin: 0;
    }
  }
}
</style>
