<template>
  <div class="items-container-notifications">
    <div v-if="items.length > 0">
      <div class="item" v-for="(item, i) in items" :key="i" >
        <div class="item_bank"><span class="icon-bank"></span>{{ 'bank' | localize }} {{ i + 1 }}</div>
        <div class="item_message" v-html="item.message"></div>
        <div class="item_point" :class="{item_point_active: item.approved == 1}"></div>
      </div>
    </div>
    <div v-else>
      <p class="info">{{ 'no_messages' | localize }}</p>
    </div>
    <div class="error__container">
      <p class="error" v-if="error">{{ error | localize }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemsListNotifications',
  props: {
    service: { type: Number, default: 1 },
    modal: { type: Boolean, default: false }
  },
  data () {
    return {
      items: [],
      error: null
    }
  },
  created () { this.reloadByService() },
  watch: {
    service: function () {
      this.reloadByService()
    }
  },
  methods: {
    setRead () {
      this.axios
        .post('user_notices/batch_update', {
          user_id: this.$store.state.user.id || 0,
          ids: this.items.map(m => m.id)
        })
        .then(() => {
          const notices = Object.assign({}, this.$store.state.notices)
          notices.notices = notices.notices > this.items.length ? notices.notices - this.items.length : notices.notices
          this.$store.commit('setNotices', notices)
        })
    },
    reloadByService () {
      if (this.service) {
        const userId = this.$store.state.user.id || 0
        this.$store.commit('loading', true)
        this.axios
          .get('users/' + userId + '/notices?service=' + this.service)
          .then((response) => {
            this.items = response.data
            const approvedItems = this.items.filter(i => i.approved === 1)
            this.$emit('items', approvedItems.length)
            this.$store.commit('loading', false)

            // Пометить все сообщения прочитанными
            if (this.items.length > 0) this.setRead()
          })
          .catch((e) => {
            this.error = 'server_error'
            this.$store.commit('loading', false)
            this.$scrollTo('.error__container', 300, { offset: -400 })
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/default/vars.scss";

.items-container-notifications {
  .item {
    border: 1px solid $white;
    background: $dark-theme;
    width: 100%;
    padding: 20px 30px;
    margin-bottom: 30px;
    position: relative;

    .item_bank {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      float: left;
      width: 30%;
      min-width: 200px;

      .icon-bank {
        font-size: 40px;
        width: 40px;
        position: absolute;
        left: 30px;
        top: 20px;
        height: 60px;
        line-height: 60px;

        &::before {
          margin: 0;
        }
      }
    }

    .item_point {
      position: absolute;
      top: 50%;
      right: 30px;
      margin-top: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $error;

      &_active {
        background: $green;
      }
    }

    .item_message {
      font-size: 16px;
      height: 60px;
      border-left: 1px solid $white;
      padding-left: 5%;
      line-height: 60px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      a {
        text-decoration: underline;
      }

      strong {
        font-weight: 600;
      }
    }
  }
  .error {
    font-size: 14px;
    font-weight: 500;
    color: $error;
  }
}

#app.light-theme {
  .items-container-notifications {
    .item {
      border: 1px solid $black;
      background: $white;

      .item_message {
        border-left: 1px solid $black;
      }

      .item_point {
        background: $error_red;

        &_active {
          background: $green_dark;
        }
      }
    }
  }
}

#app.locale_heb {
  .items-container-notifications {
    .item {
      .item_bank {
        float: right;

        .icon-bank {
          left: auto;
          right: 30px;
        }
      }

      .item_point {
        right: auto;
        left: 30px;
      }

      .item_message {
        padding-left: 0;
        padding-right: 5%;
        border-left: 0;
        border-right: 1px solid $white;
      }
    }
  }
  &.light-theme {
    .items-container-notifications {
      .item {
        .item_message {
          border-right: 1px solid $black;
        }

        .item_point {
          background: $error_red;

          &_active {
            background: $green_dark;
          }
        }
      }
    }
  }
}

// media style
@media screen and (max-width: 1024px) {
  .item_label {
    grid-row: 1/3;
    width: 32.08px;
    height: 27.96px;
  }

  .items-container-notifications {
    .item {
      .item_bank {
        float: none;
        text-align: right;
        width: 100%;
      }

      .item_point {
        top: 75%;
        margin-top: -5px;
      }

      .item_message {
        white-space: normal;
        overflow: initial;
        line-height: 24px;
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding: 10px 30px 0 0;
        border-left: 0;
        border-top: 1px solid $white;
      }
    }
  }

  #app.light-theme {
    .items-container-notifications {
      .item {
        .item_message {
          border-left: 0;
          border-top: 1px solid $black;
        }
      }
    }
  }

  #app.locale_heb {
    .items-container-notifications {
      .item {
        .item_bank {
          text-align: left;
        }

        .item_message {
          padding-left: 30px;
          padding-right: 0;
          border-left: 0;
          border-right: 0;
        }
      }
    }
    &.light-theme {
      .items-container-notifications {
        .item {
          .item_message {
            border-left: 0;
            border-right: 0;
          }
        }
      }
    }
  }
}
</style>
